/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-large.less 2013-1-16 *******/
.sidebg {
  padding-top: 0;
  padding-bottom: 0;
}
.cb-page-layout3 .maincontent,
.cb-page-layout4 .maincontent {
  padding-top: 0;
  margin-top: 355px;
  padding-bottom: 4.08496732%;
}
.cb-page-layout4 .maincontent {
  margin-top: 246px;
}
#header {
  margin-top: 0;
  min-height: 98px;
}
#edit #header {
  margin-top: 0;
}
.authenticated #header {
  margin-top: 60px;
}
#navigation {
  z-index: 7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  height: auto;
}
#edit #navigation {
  float: left;
  width: 100%;
  position: relative;
  top: 0;
}
.logoutlink {
  top: 25px;
}
div.grid .horz .trim td,
div.grid .horz .trim th {
  border-bottom: 1px dotted #8b7e7a;
  border-top: 1px dotted #8b7e7a;
}
.cb-page-layout1 div.main div.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-page-layout1 div.main div.grid div.body,
.cb-page-layout1 div.main div.grid table.horz {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-page-layout1 div.main div.grid div.body td,
.cb-page-layout1 div.main div.grid table.horz td {
  border-top: none;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
}
.area .part,
.area > .grid table {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
  width: 94.23076923%;
}
.area .tiny {
  width: 44.23076923%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 8.65384615%;
  margin-left: 8.65384615%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 82.69230769%;
}
.area > .slim .tiny {
  width: 82.69230769%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main h2,
.cb-page-layout2 .main .foot {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
}
.cb-page-layout2 .main .part,
.cb-page-layout2 .main > .grid table {
  margin-right: 2.88461538%;
  margin-left: 2.88461538%;
  width: 94.23076923%;
}
.cb-page-layout2 .main .tiny {
  width: 44.23076923%;
}
.cb-page-layout2 .main > .slim {
  width: 25%;
}
.cb-page-layout2 .main > .slim h2,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  margin-right: 11.53846154%;
  margin-left: 11.53846154%;
}
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  width: 76.92307692%;
}
.cb-page-layout2 .main > .slim .tiny {
  width: 76.92307692%;
}
.cb-page-layout3 .main {
  width: 100%;
}
.cb-page-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout3 .main h2,
.cb-page-layout3 .main .foot {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
}
.cb-page-layout3 .main .part,
.cb-page-layout3 .main > .grid table {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
  width: 91.34199134%;
}
.cb-page-layout3 .main .tiny {
  width: 41.34199134%;
}
.cb-page-layout3 .main > .slim {
  width: 50%;
}
.cb-page-layout3 .main > .slim h2,
.cb-page-layout3 .main > .slim .foot,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  margin-right: 8.65800866%;
  margin-left: 8.65800866%;
}
.cb-page-layout3 .main > .slim .part,
.cb-page-layout3 .main > .slim.grid table {
  width: 82.68398268%;
}
.cb-page-layout3 .main > .slim .tiny {
  width: 82.68398268%;
}
.cb-page-layout4 .main,
.north {
  width: 100%;
}
.cb-page-layout4 .main > .unit,
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout4 .main h2,
.north h2,
.cb-page-layout4 .main .foot,
.north .foot {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
}
.cb-page-layout4 .main .part,
.north .part,
.cb-page-layout4 .main > .grid table,
.north > .grid table {
  margin-right: 4.32900433%;
  margin-left: 4.32900433%;
  width: 91.34199134%;
}
.cb-page-layout4 .main .tiny,
.north .tiny {
  width: 41.34199134%;
}
.cb-page-layout4 .main > .slim,
.north > .slim {
  width: 50%;
}
.cb-page-layout4 .main > .slim h2,
.north > .slim h2,
.cb-page-layout4 .main > .slim .foot,
.north > .slim .foot,
.cb-page-layout4 .main > .slim .part,
.north > .slim .part,
.cb-page-layout4 .main > .slim.grid table,
.north > .slim.grid table {
  margin-right: 8.65800866%;
  margin-left: 8.65800866%;
}
.cb-page-layout4 .main > .slim .part,
.north > .slim .part,
.cb-page-layout4 .main > .slim.grid table,
.north > .slim.grid table {
  width: 82.68398268%;
}
.cb-page-layout4 .main > .slim .tiny,
.north > .slim .tiny {
  width: 82.68398268%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 10.48951049%;
  margin-left: 10.48951049%;
}
.side .part,
.side > .grid table {
  margin-right: 10.48951049%;
  margin-left: 10.48951049%;
  width: 79.02097902%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 79.02097902%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.part.auth.tile .text,
.part.auth.tile .password {
  width: 63%;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.auth.tile .password {
  width: 100%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.slim .tiny.payp.tile input.numb.grow {
  position: absolute !important;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  margin-top: 40px;
}
.cb-page-layout3 .main,
.cb-page-layout4 .main {
  margin-top: 0;
}
.cb-page-layout1 .main {
  float: left;
  max-width: 100%;
}
.side {
  margin-top: 360px;
  margin-bottom: 30px;
}
#root div.grid {
  float: right;
}
.cb-page-layout1 div.main div.wide {
  width: 66.63461538%;
}
.cb-page-layout1 div.main div.wide div.head h2 {
  margin-left: 4.32900433%;
  margin-right: 4.32900433%;
}
.cb-page-layout1 div.main div.wide div.tall {
  width: 91.34199134%;
  margin-left: 4.32900433%;
  margin-right: 4.32900433%;
}
.cb-page-layout1 div.main div.wide div.tiny {
  width: 41.26984127%;
  margin-left: 4.32900433%;
  margin-right: 4.32900433%;
}
.cb-page-layout1 div.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
h3 {
  font-size: 24px;
}
.cb-page-layout2 h3 {
  font-size: 34px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */